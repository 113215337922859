<template>
  <el-dialog
    append-to-body
    width="500px"
    title="修改分拣员"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <!--  1:分配分拣员 -->
          <el-form-item label="分拣员" prop="sorter_id">
            <el-select v-model="form.data.sorter_id" placeholder="请选择">
              <el-option
                v-for="item in sorterArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.province_str }}{{item.city_str}}</span>
              </el-option>
            </el-select>
          </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'companyContractEdit',
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            sorter_id: '',
          },
          rules: {
            sorter_id: [{ required: true, message:'请选择分拣员', trigger: ['change','blur']}],
          }
        },
        sorterArr: [],
        detail_ids: '', //明细id
      }
    },
    created() {
      this.getSorterList();
    },
    methods: {
      getDetail(row,type) {
        if(type == 1) { // type:1单条修改分拣员，处理反显值，批量则不需要反显
          this.detail_ids = row.id;
          let str = this.sorterArr.some(v => v.id == row.sorter_id); // 检测数组中的元素是否满足指定条件，如果没有满足条件的元素返回false
          this.form.data.sorter_id = (row.sorter_id == 0 || str == false ) ? '' : row.sorter_id; //反显赋值
        } else {// 批量修改分拣员
          this.detail_ids = row.map(v => { return v.id }).join("|")
          this.form.data.sorter_id = '';
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 分配分拣员提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _param = {};
            _param = {
              sorter_id: this.form.data.sorter_id,
              detail_ids: this.detail_ids,
            }
            this.form.loading = true;
            this.$http.post('/admin/sorter_order/allot', _param).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 获取分拣员列表
      getSorterList() {
        this.$http.post('/admin/sorter/list',{page:1,count:1000}).then(res => {
          if(res.code == 1) {
            this.sorterArr = res.data.list;
          }
        })
      }
    }
  }
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /* .idPhoto .avatar{
    width: 100% !important;
  } */
  .idPhoto .avatar-uploader-icon {
    width: 178px !important;
    height: 178px !important;
    line-height: 178px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>
